body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.d3-tip {
  padding-bottom: 10px;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.d3-tip-container {
  background-color: #505f79;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
}

.d3-tip-label {
  text-align: center;
  font-size: 12px;
}

.d3-tip-value {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
}
